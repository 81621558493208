import React from 'react';
import { IframeStyles } from './Iframe.styles';
const Iframe = (props) => {
    if (!props.url || !props.url.length) {
        return null;
    }
    return (React.createElement(IframeStyles.Wrapper, null,
        React.createElement(IframeStyles.ResponsiveWrapper, { aspectRatio: props.aspectRatio },
            React.createElement(IframeStyles.Iframe, { title: props.title, allowFullScreen: true, src: props.url }))));
};
export default Iframe;
