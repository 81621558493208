import styled from 'styled-components';
const Wrapper = styled.div ``;
const ResponsiveWrapper = styled.div `
  height: 0px;
  padding-bottom: ${({ aspectRatio }) => aspectRatio}%;
  position: relative;
`;
const Iframe = styled.iframe `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export const IframeStyles = {
    Wrapper,
    ResponsiveWrapper,
    Iframe,
};
